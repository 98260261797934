<template>
  <div class="branch-subset-selection">
    <b-button class="branch-subset-selection__open-modal-button" variant="inverse-primary" @click="handleOpenModalButtonIsClicked">
      選擇分店
    </b-button>

    <div class="branch-subset-selection__preview-selection ml-3">
      <b-spinner style="border-width: 1px" small v-if="isFetchingPreselectedBranchModels" />
      <template v-else>
        <span class="branch-subset-selection__selected-branch-name" v-for="(selectedShopModel, i) in sortedSelectedBranchModels" :key="i" :class="{ 'd-none': i >= previewBranchNameCount }">
          {{ deepGet(selectedShopModel, 'name') }}
        </span>
        <span class="branch-subset-selection__selected-branch-name" v-if="selectedShopModels.length > previewBranchNameCount">+{{ selectedShopModels.length - previewBranchNameCount }}</span>
      </template>
    </div>

    <!-- 選擇分店來源 Modal Start -->
    <b-modal
      ref="modal"
      title="選擇分店來源"
      cancel-title="取消"
      size="lg"
      dialog-class="branch-subset-selection__modal"
      @shown="handleAreaSelectionModalIsShown"
      @ok="handleOkIsClicked"
      @cancel="handleCancelIsClicked"
    >
      <div class="branch-subset-selection__candidate-section">
        <div class="branch-subset-selection__header-section">
          <AppSingleSelect
            v-model="searchedCandidateBranchId"
            :options="branchOptions"
            placeholder="查詢分店"
            @input="handleSearchedCandidateBranchChanged"
          />
          <hr class="my-3" />
          <label class="d-inline-flex align-items-center mb-3">
            <span class="mr-2" style="color: #777">是否顯示分店代號</span>
            <b-form-checkbox
              v-model="showBranchCode"
              :value="true"
              :unchecked-value="false"
            ></b-form-checkbox>
          </label>

          <AreaSelection
            ref="area-selection-for-branch-subset"
            @selected="handleAreaIsSelected"
            @childrenAreUpdated="handleChildrenBranchesAreUpdated"
            branch-provider="waltily.branch"
            :show-branch-code="showBranchCode"
            placeholder="請選擇區域"
            api-source="admin"
          />

          <p class="mt-4 mb-2" style="color: #777" v-if="candidateShopModels.length > 0">
            請選擇分店：
          </p>
        </div>

        <div class="branch-subset-selection__scrollable-body">
          <template v-if="isFetchingCandidates">
            <div class="d-flex justify-content-center align-items-center" style="height: 64px">
              <b-spinner style="border-width: 2px" />
            </div>
          </template>
          <template v-else>
            <div class="branch-subset-selection__candidate-branch" v-if="candidateShopModels.length > 1" @click="handleSelectAllShopIsClicked">
              <button
                class="branch-subset-selection__select-button"
                :class="{ 'branch-subset-selection__select-button--selected': allCandidatesAreSelected }"
              >
                <i class="mdi mdi-24px mdi-check"></i>
              </button>
              <span class="mx-4">全選</span>
            </div>
            <div class="branch-subset-selection__candidate-branch" v-for="(candidateShopModel, i) in candidateShopModels" :key="i" @click="handleSelectShopIsClicked(candidateShopModel)">
              <button
                class="branch-subset-selection__select-button"
                :class="{ 'branch-subset-selection__select-button--selected': selectedShopIds.includes(candidateShopModel.id) }"
              >
                <i class="mdi mdi-24px mdi-check"></i>
              </button>
              <span class="mx-4">{{ showBranchCode ? deepGet(candidateShopModel, 'branch_code') + ' ' : '' }}{{ deepGet(candidateShopModel, 'name') }}</span>
            </div>
          </template>
        </div>
      </div>

      <div class="branch-subset-selection__selected-section">
        <div class="branch-subset-selection__header-section">
          <p class="mb-2" style="color: #777">已選分店，共 {{ selectedShopModels.length  }} 間</p>
          <b-input
            type="text"
            class="form-control my-2"
            maxlength="20"
            placeholder="查詢已選分店"
            v-model="selectedShopKeyword"
            v-if="selectedShopModels.length > 0 && false === isFetchingPreselectedBranchModels"
          />
        </div>

        <div class="branch-subset-selection__scrollable-body">
          <template v-if="isFetchingPreselectedBranchModels">
            <div class="d-flex justify-content-center align-items-center" style="height: 64px">
              <b-spinner style="border-width: 2px" />
            </div>
          </template>
          <template v-else>
            <div class="branch-subset-selection__selected-branch" v-for="(selectedShopModel, i) in filteredSelectedBranchModels" :key="i">
              <span class="mr-4">{{ showBranchCode ? deepGet(selectedShopModel, 'branch_code') + ' ' : '' }}{{ deepGet(selectedShopModel, 'name') }}</span>
              <button class="branch-subset-selection__clean-button ml-auto" @click="handleCleanSelectedShopIsClicked(selectedShopModel)">
                <i class="mdi mdi-24px mdi-close"></i>
              </button>
            </div>
          </template>
        </div>
      </div>
    </b-modal>
    <!-- 選擇分店來源 Modal End -->
  </div>
</template>

<script>
import branchApiAdmin from "@/apis/branch"
import AppSingleSelect from "@/components/AppSingleSelect.vue";
import AreaSelection from "@/components/Page/Liff/Branch/AreaSelection"
import { mapState } from 'vuex'
import _ from "lodash"

export default {
  components: {
    AppSingleSelect,
    AreaSelection,
  },
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      candidateShopModels: [],
      isFetchingCandidates: false,
      selectedShopIds: [],
      selectedShopModels: [],
      showBranchCode: false,
      selectedShopKeyword: '',
      isFetchingPreselectedBranchModels: false,
      hasFetchedPreselectedBranchModels: false,
      searchedCandidateBranchId: null,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      myBranches: (state) => state.myBranches,
    }),
    previewBranchNameCount() {
      return 2
    },
    filteredSelectedBranchModels() {
      return this.sortedSelectedBranchModels.filter(selectedShopModel => {
        if (_.get(this.selectedShopKeyword, 'length', 0) === 0) {
          return true
        }
        return selectedShopModel.name.includes(this.selectedShopKeyword) || selectedShopModel.branch_code.includes(this.selectedShopKeyword)
      })
    },
    sortedSelectedBranchModels() {
      return _.sortBy(this.selectedShopModels, selectedShopModel => this.selectedShopIds.indexOf(selectedShopModel.id))
    },
    allCandidatesAreSelected() {
      return this.candidateShopModels.every(candidateShopModel => this.selectedShopIds.includes(candidateShopModel.id))
    },
    branchOptions() {
      return [
        { text: '請選擇分店', value: null },
        ...this.myBranches
          .filter(branch => ['area', 'shop'].includes(branch.branch_type))
          .map(branch => ({
            text: `${branch.branch_code} ${branch.name}`,
            value: branch.id,
            model: branch,
          }))
      ]
    },
  },
  async mounted() {
    this.selectedShopIds = this.value
    if (this.selectedShopIds.length > 0) {
      await this._fetchPreselectedBranchModels()
    }
  },
  methods: {
    deepGet: _.get,
    isEqual: _.isEqual,
    async handleOpenModalButtonIsClicked() {
      this.$refs.modal.show()
      this.selectedShopIds = this.value
      if (this.selectedShopIds.length > 0 && false === this.hasFetchedPreselectedBranchModels) {
        await this._fetchPreselectedBranchModels()
      }
    },
    async handleAreaSelectionModalIsShown() {
      await this.$refs[`area-selection-for-branch-subset`].fetchBranchesOptions()
    },
    async handleSearchedCandidateBranchChanged(value) {
      if (value === null || _.get(value, 'value') === null) {
        this.candidateShopModels = []
        return
      }

      if (_.get(value, 'model.branch_type') === 'area') {
        this.isFetchingCandidates = true
        this.candidateShopModels = await this.fetchShopsByArea(value.value)
        this.isFetchingCandidates = false
        return
      }

      if (_.get(value, 'model.branch_type') === 'shop') {
        this.candidateShopModels = [_.get(value, 'model')]
        return
      }
    },
    async handleAreaIsSelected(value) {
      if (value.type !== 'area') {
        return
      }

      this.isFetchingCandidates = true
    },
    handleChildrenBranchesAreUpdated(branchModels) {
      this.candidateShopModels = branchModels.filter(branchModel => branchModel.branch_type === 'shop')
      this.isFetchingCandidates = false
    },
    handleSelectAllShopIsClicked() {
      if (this.allCandidatesAreSelected) {
        this.candidateShopModels.forEach(candidateShopModel => {
          this.selectedShopIds = this.selectedShopIds.filter(shopId => shopId !== candidateShopModel.id)
          this.selectedShopModels = this.selectedShopModels.filter(shopModel => shopModel.id !== candidateShopModel.id)
        })
      } else {
        this.candidateShopModels.forEach(candidateShopModel => {
          if (!this.selectedShopIds.includes(candidateShopModel.id)) {
            this.selectedShopIds.push(candidateShopModel.id)
            this.selectedShopModels.push(candidateShopModel)
          }
        })
      }
    },
    handleSelectShopIsClicked(candidateShopModel) {
      if (this.selectedShopIds.includes(candidateShopModel.id)) {
        this.selectedShopIds = this.selectedShopIds.filter(shopId => shopId !== candidateShopModel.id)
        this.selectedShopModels = this.selectedShopModels.filter(shopModel => shopModel.id !== candidateShopModel.id)
      } else {
        this.selectedShopIds.push(candidateShopModel.id)
        this.selectedShopModels.push(candidateShopModel)
      }
    },
    handleCleanSelectedShopIsClicked(selectedShopModel) {
      this.selectedShopIds = this.selectedShopIds.filter(shopId => shopId !== selectedShopModel.id)
      this.selectedShopModels = this.selectedShopModels.filter(shopModel => shopModel.id !== selectedShopModel.id)
    },
    handleOkIsClicked() {
      this.$emit('input', this.selectedShopIds)
    },
    handleCancelIsClicked() {
      //
    },
    async fetchShopsByArea(areaId) {
      try {
        return _.get(await branchApiAdmin.getBranchOptions(this.organization, { type: 'level', branch_id: areaId }), 'data.data', [])
          .filter(branch => branch.branch_type === 'shop')
      } catch (error) {
        console.error('[BranchSubsetEditor] Failed to fetch branches.')
        console.error(error)
      }
    },
    async fetchBranchModels(branchIds) {
      try {
        return _.get(await branchApiAdmin.getBranches(this.organization, { branch_ids: branchIds, is_all: true }), 'data.data', [])
      } catch (error) {
        console.error('[BranchSubsetEditor] Failed to fetch branches.')
        console.error(error)
      }
    },
    async _fetchPreselectedBranchModels() {
      this.isFetchingPreselectedBranchModels = true
      this.selectedShopModels = await this.fetchBranchModels(this.selectedShopIds)
      this.isFetchingPreselectedBranchModels = false
      this.hasFetchedPreselectedBranchModels = true
    },
    // public api: fetch branch models to display names of the selected branch ids
    async fetchPreselectedBranches() {
      await this._fetchPreselectedBranchModels()
    },
  },
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/base-colors";

::v-deep .branch-subset-selection__modal {
  max-width: 800px;
  max-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;

  .modal-content {
    max-height: 100%;
    overflow: hidden;

    .modal-header, .modal-footer {
      flex: 0 0 auto;
    }

    .modal-body {
      flex: 1 1 auto;
      overflow-y: hidden;
      display: flex;
      padding: 16px 32px;
    }
  }
}

.branch-subset-selection {
  display: inline-flex;
  align-items: center;

  &__preview-selection {
    display: inline-flex;
  }

  &__selected-branch-name {
    display: inline-block;
    border: 1px solid map-get(base-colors.$theme-colors, warning);
    border-radius: 8px;
    padding: 4px 8px;
    color: map-get(base-colors.$theme-colors, warning);
    font-size: 0.75rem;
  }

  &__selected-branch-name + &__selected-branch-name {
    margin-left: 8px;
  }

  &__candidate-section {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    padding-right: 32px;
    border-right: 1px solid #e9ecef;
  }

  &__selected-section {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    padding-left: 32px;
  }

  &__header-section {
    flex: 0 0 auto;
  }

  &__scrollable-body {
    flex: 1 1 auto;
    overflow-y: auto;
  }

  &__candidate-branch {
    display: flex;
    align-items: center;
    padding: 16px 8px;
    cursor: pointer;
  }

  &__candidate-branch + &__candidate-branch {
    border-top: 1px solid #e9ecef;
  }

  &__selected-branch {
    display: flex;
    align-items: center;
    padding: 16px 8px;
  }

  &__selected-branch + &__selected-branch {
    border-top: 1px solid #e9ecef;
  }

  &__select-button {
    height: 32px;
    width: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;

    &--selected {
      background-color: map-get(base-colors.$theme-colors, success);
    }
  }

  &__clean-button {
    height: 32px;
    width: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: map-get(base-colors.$theme-colors, danger);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
  }
}
</style>
