<template>
  <div>
    <h4 class="mb-3 font-weight-bold">上限頁</h4>
    <validation-error-alert
      v-if="validationErrors"
      :errors="validationErrors"
    ></validation-error-alert>
    <b-form-group
      label-cols="12"
      label-cols-lg="3"
      label-size="sm"
      content-cols="12"
      content-cols-lg="6"
      :disabled="isReadOnly"
    >
      <template #label>
        Banner
        <p class="text-black-50 mt-2" style="font-size: .75rem">寬 x 高：1400px x 700px</p>
      </template>
      <template #default>
        <li
          class="s-image-list-item s-draggable"
          v-if="selectedImage"
        >
          <img class="s-cropped-image" :src="selectedImage" />
          <button
            class="btn btn-danger s-delete-btn"
            type="button"
            @click="selectedImage = null"
          >
            Ｘ
          </button>
        </li>
        <li class="s-image-list-item" v-else>
          <ImageSelector :aspect-ratio="1400 / 700" @input="(croppedImage) => selectedImage = croppedImage">
            <template #trigger="{ openFileSelector }">
              <button class="btn s-trigger-btn" @click="openFileSelector">
                ＋ 加入照片
              </button>
            </template>
          </ImageSelector>
        </li>
      </template>
    </b-form-group>
    <!-- 標題 Start -->
    <b-form-group
      label-cols="12"
      label-cols-lg="3"
      label-size="sm"
      label="標題"
      :disabled="isReadOnly"
    >
      <b-form-input
        class="mb-2 mr-sm-2 mb-sm-0"
        type="text"
        :state="deepGet(v$, 'form.config.limit_page.form_title.$error') ? false : null"
        v-model="form.config.limit_page.form_title"
      ></b-form-input>
      <b-form-invalid-feedback :state="!deepGet(v$, 'form.config.limit_page.form_title.$error')">
        此欄位為必填
      </b-form-invalid-feedback>
    </b-form-group>
    <!-- 標題 End -->
    <!-- 副標題 Start -->
    <b-form-group
      label-cols="12"
      label-cols-lg="3"
      label-size="sm"
      label="副標題"
      :disabled="isReadOnly"
    >
      <b-form-input
        class="mb-2 mr-sm-2 mb-sm-0"
        type="text"
        :state="deepGet(v$, 'form.config.limit_page.form_desc.$error') ? false : null"
        v-model="form.config.limit_page.form_desc"
      ></b-form-input>
      <b-form-invalid-feedback :state="!deepGet(v$, 'form.config.limit_page.form_desc.$error')">
        此欄位為必填
      </b-form-invalid-feedback>
    </b-form-group>
    <!-- 副標題 End -->
    <!-- 內容說明 Start -->
    <b-form-group
      label-cols="12"
      label-cols-lg="3"
      label-size="sm"
      label="內容說明"
      :disabled="isReadOnly"
    >
      <TrixEditor v-model="form.config.limit_page.content" :readonly="isReadOnly"/>
      <b-form-invalid-feedback :state="!deepGet(v$, 'form.config.limit_page.content.$error')">
        此欄位為必填
      </b-form-invalid-feedback>
    </b-form-group>
    <!-- 內容說明 End -->
    <!-- 按鈕文字 Start -->
    <b-form-group
      label-cols="12"
      label-cols-lg="3"
      label-size="sm"
      label="按鈕文字"
      :disabled="isReadOnly"
    >
      <b-form-input
        class="mb-2 mr-sm-2 mb-sm-0"
        type="text"
        :state="deepGet(v$, 'form.config.limit_page.button_text.$error') ? false : null"
        v-model="form.config.limit_page.button_text"
      ></b-form-input>
      <b-form-invalid-feedback :state="!deepGet(v$, 'form.config.limit_page.button_text.$error')">
        此欄位為必填
      </b-form-invalid-feedback>
    </b-form-group>
    <!-- 按鈕文字 End -->
    <!-- 表單類型 Start -->
    <b-form-group
      label-cols="12"
      label-cols-lg="3"
      label-size="sm"
      label="*表單類型(尚未串接)"
      :disabled="isReadOnly"
    >
      <div class="radio-button-group-grid mt-1">
        <b-form-radio v-model="form.config.limit_page.button_action" name="button_action" id="OA" value="OA" @input="cleanButtonUrl"></b-form-radio>
        <div class="radio-button-group-grid__option-content">
          <label for="OA" class="mb-0">回官方帳號</label>
        </div>

        <b-form-radio v-model="form.config.limit_page.button_action" name="button_action" id="URL" value="URL" @input="cleanButtonUrl"></b-form-radio>
        <div class="radio-button-group-grid__option-content">
          <label for="URL" class="mb-0">自訂網址</label>
          <b-form-input
            class="ml-3"
            type="text"
            :disabled="form.config.limit_page.button_action !== 'URL'"
            :state="deepGet(v$, 'form.config.limit_page.button_url.$error') ? false : null"
            v-model="form.config.limit_page.button_url"
          ></b-form-input>
        </div>
      </div>
    </b-form-group>
    <!-- 表單類型 End -->
    <!-- 表單底部 Start -->
    <div class="d-flex justify-content-center" v-if="!isReadOnly">
      <b-button
        class="mr-3"
        @click="$emit('cancel')"
        variant="outline-danger"
      >
        返回
      </b-button>
      <b-button
        @click="submitPage"
        variant="success"
      >
        儲存
      </b-button>
    </div>
    <!-- 表單底部 End -->
  </div>
</template>

<script>
import ImageSelector from "@/components/ImageSelector";
import TrixEditor from "@/components/TrixEditor";
import deepGet from "@/utils/deepGet";
import imageMixin from "@/mixins/uploadImage";
import useVuelidate from "@vuelidate/core";

export default {
  mixins: [imageMixin],
  components: {
    ImageSelector,
    TrixEditor,
  },
  setup: () => ({ v$: useVuelidate() }),
  props: {
    form: Object,
    isReadOnly: Boolean,
    imageUrl: String,
  },
  data() {
    return {
      validationErrors: null,
      selectedImage: this.imageUrl,
    };
  },
  methods: {
    deepGet,
    cleanButtonUrl() {
      if (this.form.config.limit_page.button_action !== 'URL') {
        this.form.config.limit_page.button_url = '';
      }
    },
    async submitPage() {
      if (!this.selectedImage) {
        this.$set(this.form.config.limit_page, 'banner_image', null);
      }

      if (this.selectedImage && this.selectedImage !== this.form.config.limit_page.banner_image) {
        this.$emit('loading', true);
        try {
          const imageUrl = await this.uploadByBase64(this.selectedImage);
          this.$set(this.form.config.limit_page, 'banner_image', imageUrl);
        } catch (e) {
          this.$emit('loading', false);
        } finally {
          this.$emit('loading', false);
        }
      }

      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss" scoped>
.s-image-list {
  margin: -0.75rem;
  display: flex;
  flex-wrap: wrap;
}

.s-image-list-item {
  position: relative;
  width: fit-content;
  height: 7rem;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
}

.s-cropped-image {
  height: 100%;
}

.s-delete-btn {
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.s-image-selector {
  width: 100%;
  height: 100%;
}

.s-trigger-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.radio-button-group-grid {
  display: grid;
  grid-gap: 8px 0;
  grid-template-columns: auto 1fr;
  align-items: center;

  &__option-content {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
}
</style>
