<template>
  <div>
    <h4 class="mb-3 font-weight-bold">核銷頁</h4>
    <validation-error-alert
      v-if="validationErrors"
      :errors="validationErrors"
    ></validation-error-alert>
    <b-form-group
      label-cols="12"
      label-cols-lg="3"
      label-size="sm"
      content-cols="12"
      content-cols-lg="6"
      :disabled="isReadOnly"
    >
      <template #label>
        Banner
        <p class="text-black-50 mt-2" style="font-size: .75rem">寬 x 高：1400px x 700px</p>
      </template>
      <template #default>
        <li
          class="s-image-list-item s-draggable"
          v-if="selectedImage"
        >
          <img class="s-cropped-image" :src="selectedImage" />
          <button
            class="btn btn-danger s-delete-btn"
            type="button"
            @click="selectedImage = null"
          >
            Ｘ
          </button>
        </li>
        <li class="s-image-list-item" v-else>
          <ImageSelector :aspect-ratio="1400 / 700" @input="(croppedImage) => selectedImage = croppedImage">
            <template #trigger="{ openFileSelector }">
              <button class="btn s-trigger-btn" @click="openFileSelector">
                ＋ 加入照片
              </button>
            </template>
          </ImageSelector>
        </li>
      </template>
    </b-form-group>
    <!-- 標題 Start -->
    <b-form-group
      label-cols="12"
      label-cols-lg="3"
      label-size="sm"
      label="標題"
      :disabled="isReadOnly"
    >
      <b-form-input
        class="mb-2 mr-sm-2 mb-sm-0"
        type="text"
        :state="deepGet(v$, 'form.config.confirm_page.form_title.$error') ? false : null"
        v-model="form.config.confirm_page.form_title"
      ></b-form-input>
      <b-form-invalid-feedback :state="!deepGet(v$, 'form.config.confirm_page.form_title.$error')">
        此欄位為必填
      </b-form-invalid-feedback>
    </b-form-group>
    <!-- 標題 End -->
    <!-- 副標題 Start -->
    <b-form-group
      label-cols="12"
      label-cols-lg="3"
      label-size="sm"
      label="副標題"
      :disabled="isReadOnly"
    >
      <b-form-input
        class="mb-2 mr-sm-2 mb-sm-0"
        type="text"
        :state="deepGet(v$, 'form.config.confirm_page.form_desc.$error') ? false : null"
        v-model="form.config.confirm_page.form_desc"
      ></b-form-input>
      <b-form-invalid-feedback :state="!deepGet(v$, 'form.config.confirm_page.form_desc.$error')">
        此欄位為必填
      </b-form-invalid-feedback>
    </b-form-group>
    <!-- 副標題 End -->
    <!-- 內容說明 Start -->
    <b-form-group
      label-cols="12"
      label-cols-lg="3"
      label-size="sm"
      label="內容說明"
      :disabled="isReadOnly"
    >
      <TrixEditor v-model="form.config.confirm_page.content" :readonly="isReadOnly"/>
      <b-form-invalid-feedback :state="!deepGet(v$, 'form.config.confirm_page.content.$error')">
        此欄位為必填
      </b-form-invalid-feedback>
    </b-form-group>
    <!-- 內容說明 End -->
    <b-form-group
      label-cols="12"
      label-cols-lg="3"
      label-size="sm"
      label="核銷設定"
    >
      <b-form-radio-group
        v-model="form.config.confirm_page.confirm_type"
        :options="confirmTypes"
        :state="v$.form.config.confirm_page.confirm_type.$error ? false : null"
        :disabled="isReadOnly"
      >
      </b-form-radio-group>
      <b-form-invalid-feedback :state="!v$.form.config.confirm_page.confirm_type.$error">
        此欄位為必填
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label-cols="12"
      label-cols-lg="3"
      label-size="sm"
      label="核銷期限"
    >
      <div class="d-flex align-items-center mb-2">
        <b-form-radio :disabled="isReadOnly" v-model="form.config.confirm_page.period_type" value="by_form">
          與表單期限相同
        </b-form-radio>
      </div>
      <div class="d-flex align-items-center mb-2">
        <b-form-radio :disabled="isReadOnly" v-model="form.config.confirm_page.period_type" value="custom" class="mr-2">
          自訂期限
        </b-form-radio>
        <div class="d-flex align-items-center">
          <datepicker
            placeholder="選擇日期"
            v-model="form.config.confirm_page.period_start_at"
            bootstrap-styling
            :language="zh"
            format="yyyy-MM-dd"
            :disabled="isReadOnly || form.config.confirm_page.period_type !== 'custom'"
            :clear-button="!isReadOnly"
            :input-class="deepGet(v$, 'form.config.confirm_page.period_start_at.$error') ? 'is-invalid' : ''"
          ></datepicker>
          <span class="mx-2">~</span>
          <datepicker
            placeholder="選擇日期"
            v-model="form.config.confirm_page.period_end_at"
            bootstrap-styling
            :language="zh"
            format="yyyy-MM-dd"
            :disabled="isReadOnly || form.config.confirm_page.period_type !== 'custom'"
            :clear-button="!isReadOnly"
            :input-class="deepGet(v$, 'form.config.confirm_page.period_end_at.$error') ? 'is-invalid' : ''"
          ></datepicker>
        </div>
      </div>
      <b-form-invalid-feedback :state="!v$.form.config.confirm_page.period_type.$error">
        此欄位為必填
      </b-form-invalid-feedback>
    </b-form-group>
    <!-- 表單底部 Start -->
    <div class="d-flex justify-content-center" v-if="!isReadOnly">
      <b-button
        class="mr-3"
        @click="$emit('cancel')"
        variant="outline-danger"
      >
        返回
      </b-button>
      <b-button
        @click="submitPage"
        variant="success"
      >
        儲存
      </b-button>
    </div>
    <!-- 表單底部 End -->
  </div>
</template>

<script>
import ImageSelector from "@/components/ImageSelector";
import TrixEditor from "@/components/TrixEditor";
import deepGet from "@/utils/deepGet";
import imageMixin from "@/mixins/uploadImage";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Datepicker from "vuejs-datepicker";
import { zh } from "vuejs-datepicker/dist/locale";
import Swal from "sweetalert2";

export default {
  mixins: [imageMixin],
  components: {
    ImageSelector,
    TrixEditor,
    Datepicker,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      form: {
        config: {
          confirm_page: {
            confirm_type: { required },
            period_type: { required }
          },
        },
      },
    };
  },
  props: {
    form: Object,
    isReadOnly: Boolean,
    imageUrl: String,
  },
  data() {
    return {
      zh,
      validationErrors: null,
      selectedImage: this.imageUrl,
      confirmTypes: [
        {
          text: "使用後自動核銷（五分鐘內需使用）",
          value: 'auto',
        },
        {
          text: "使用後手動核銷（若未手動核銷，可重複使用）",
          value: 'manual',
        },
        {
          text: "無核銷按鈕",
          value: 'none',
        },
        {
          text: "輸入店號核銷",
          value: 'branch_code',
        },
      ],
    };
  },
  methods: {
    deepGet,
    async submitPage() {
      const result = await this.v$.$validate();
      if (!result) return false;

      if (!this.selectedImage) {
        this.$set(this.form.config.confirm_page, 'banner_image', null);
      }

      if (this.selectedImage && this.selectedImage !== this.form.config.confirm_page.banner_image) {
        this.$emit('loading', true);
        try {
          const imageUrl = await this.uploadByBase64(this.selectedImage);
          this.$set(this.form.config.confirm_page, 'banner_image', imageUrl);
        } catch (e) {
          this.$emit('loading', false);
        } finally {
          this.$emit('loading', false);
        }
      }

      console.log(this.form.config.confirm_page);
      if (this.form.config.confirm_page.period_end_at < this.form.config.confirm_page.period_start_at) {
        await Swal.fire({
          title: '核銷期限的自訂期限，結束日期不得小於開始日期',
          type: 'error'
        });

        return;
      }

      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss" scoped>
.s-image-list {
  margin: -0.75rem;
  display: flex;
  flex-wrap: wrap;
}

.s-image-list-item {
  position: relative;
  width: fit-content;
  height: 7rem;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
}

.s-cropped-image {
  height: 100%;
}

.s-delete-btn {
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.s-image-selector {
  width: 100%;
  height: 100%;
}

.s-trigger-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.radio-button-group-grid {
  display: grid;
  grid-gap: 8px 0;
  grid-template-columns: auto 1fr;
  align-items: center;

  &__option-content {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
}
</style>
