<template>
  <div class="branch-provider-selection">
    <b-button class="branch-provider-selection__open-modal-button" variant="inverse-primary" @click="handleOpenModalButtonIsClicked">
      選擇分店來源
    </b-button>

    <b-spinner class="ml-3" style="border-width: 1px" small v-if="isFetchingSelectedBranchModel" />
    <div class="branch-provider-selection__preview-selection ml-3" v-else-if="selectedBranchModel">
      {{ formattedBranchName(selectedBranchModel) }}
    </div>

    <!-- 選擇分店來源 Modal Start -->
    <b-modal
      ref="modal"
      title="選擇分店來源"
      cancel-title="取消"
      @shown="handleAreaSelectionModalIsShown"
      @ok="handleOkIsClicked"
      @cancel="handleCancelIsClicked"
    >
      <label class="d-inline-flex align-items-center mb-2">
        <span class="mr-2" style="color: #777">是否顯示分店代號</span>
        <b-form-checkbox
          v-model="vModelForShowBranchCode"
          :value="true"
          :unchecked-value="false"
          @input="value => $emit('update:showBranchCode', value)"
        ></b-form-checkbox>
      </label>

      <AreaSelection
        ref="area-selection-for-branch-provider"
        v-model="currentBranchId"
        @selected="handleAreaIsSelected"
        branch-provider="waltily.branch"
        :show-branch-code="vModelForShowBranchCode"
        placeholder="請選擇區域"
        api-source="admin"
      />

      <template v-if="currentBranchModel">
        <p class="mt-4 mb-2" style="color: #777">
          請選擇分店來源：
        </p>
        <div class="branch-provider-selection__candidate-branch" @click="handleSelectBranchIsClicked">
          <button
            class="branch-provider-selection__select-button"
            :class="{ 'branch-provider-selection__select-button--selected': deepGet(currentBranchModel, 'branch_code') === deepGet(selectedBranchModel, 'branch_code') }"
          >
            <i class="mdi mdi-24px mdi-check"></i>
          </button>
          <span class="mx-4">{{ formattedBranchName(currentBranchModel) }}</span>
        </div>
      </template>

      <p class="mt-4 mb-2" style="color: #777">
        目前選擇的分店來源：{{ selectedBranchModel ? '' : '尚未選擇' }}
      </p>
      <div class="branch-provider-selection__selected-branch" v-if="selectedBranchModel">
        <button class="branch-provider-selection__clean-button" @click="handleCleanSelectedBranchIsClicked">
          <i class="mdi mdi-24px mdi-close"></i>
        </button>
        <span class="mx-4">{{ formattedBranchName(selectedBranchModel) }}</span>
      </div>
    </b-modal>
    <!-- 選擇分店來源 Modal End -->
  </div>
</template>

<script>
import branchApiAdmin from "@/apis/branch"
import AreaSelection from "@/components/Page/Liff/Branch/AreaSelection"
import _ from "lodash"
import { mapState } from 'vuex'

const fallbackBranchModel = {
  id: '',
  name: '所有區域',
  branch_code: '',
}

export default {
  components: {
    AreaSelection,
  },
  props: {
    value: {
      type: String,
      required: false,
    },
    showBranchCode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentBranchId: null,
      currentBranchModel: fallbackBranchModel,
      selectedBranchId: null,
      selectedBranchModel: null,
      branchProvider: null,
      isFetchingSelectedBranchModel: false,
      vModelForShowBranchCode: false,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  watch: {
    showBranchCode(value) {
      this.vModelForShowBranchCode = value
    },
  },
  mounted() {
    this.vModelForShowBranchCode = this.showBranchCode
  },
  methods: {
    deepGet: _.get,
    isEqual: _.isEqual,
    handleOpenModalButtonIsClicked() {
      this.$refs.modal.show()
    },
    async handleAreaSelectionModalIsShown() {
      if (this.value) {
        const selectedBranchCode = this.parseBranchProviderToBranchCode(this.value)
        if (selectedBranchCode === '') {
          this.selectedBranchModel = fallbackBranchModel
          await this.$refs[`area-selection-for-branch-provider`].fetchBranchesOptions()
        } else {
          await this.$refs[`area-selection-for-branch-provider`].loadBranchOptionsBySelectedAreaId(null, selectedBranchCode)
          const allSelectedBranches = this.$refs[`area-selection-for-branch-provider`].allSelectedBranches()
          this.selectedBranchModel = allSelectedBranches.find(branch => branch.branch_code === selectedBranchCode)
          this.currentBranchModel = this.selectedBranchModel
        }
      } else {
        await this.$refs[`area-selection-for-branch-provider`].fetchBranchesOptions()
      }
    },
    handleAreaIsSelected(value) {
      this.currentBranchModel = value.model

      const allSelectedBranches = this.$refs[`area-selection-for-branch-provider`].allSelectedBranches()
      if (allSelectedBranches.length === 0) {
        this.currentBranchModel = fallbackBranchModel
      }
    },
    handleSelectBranchIsClicked() {
      if (_.isEqual(this.currentBranchModel, this.selectedBranchModel)) {
        this.selectedBranchId = null
        this.selectedBranchModel = null
      } else {
        this.selectedBranchId = this.currentBranchId
        this.selectedBranchModel = this.currentBranchModel
      }
    },
    handleCleanSelectedBranchIsClicked() {
      this.selectedBranchId = null
      this.selectedBranchModel = null
    },
    handleOkIsClicked() {
      const branchCode = _.get(this.selectedBranchModel, 'branch_code')
      if (this.selectedBranchModel) {
        this.$emit('input', this.parseBranchCodeToBranchProvider(branchCode))
      } else {
        this.currentBranchId = null
        this.currentBranchModel = fallbackBranchModel
        this.selectedBranchId = null
        this.selectedBranchModel = null
        this.$emit('input', null)
      }
    },
    handleCancelIsClicked() {
      //
    },
    formattedBranchName(branchModel) {
      return [
        this.vModelForShowBranchCode ? _.get(branchModel, 'branch_code') : null,
        _.get(branchModel, 'name')
      ]
        .filter(Boolean)
        .join(' ')
    },
    parseBranchProviderToBranchCode(branchProvider) {
      return _.get((branchProvider || '').split(':'), 1, '')
    },
    parseBranchCodeToBranchProvider(branchCode) {
      return ['waltily.branch', branchCode]
        .filter(str => false === [null, '']
        .includes(str))
        .join(':')
    },
    async fetchBranches(params) {
      try {
        return _.get(await branchApiAdmin.getBranchOptions(this.organization, params), 'data.data', [])
      } catch (error) {
        console.error('[BranchProviderEditor] Failed to fetch branches.')
        console.error(error)
      }
    },
    // public api: parse branch code and find branch model
    async loadSelectedBranch() {
      const selectedBranchCode = this.parseBranchProviderToBranchCode(this.value)

      if (this.value && selectedBranchCode === '') {
        this.selectedBranchModel = fallbackBranchModel
        this.selectedBranchId = _.get(this.selectedBranchModel, 'id')
        return
      }

      this.isFetchingSelectedBranchModel = true
      const allBranchModels = await this.fetchBranches({ type: 'all' })
      this.selectedBranchModel = allBranchModels.find(branchModel => branchModel.branch_code === selectedBranchCode)
      this.selectedBranchId = _.get(this.selectedBranchModel, 'id')
      this.isFetchingSelectedBranchModel = false
    },
  },
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/base-colors";

.branch-provider-selection {
  display: inline-flex;
  align-items: center;

  &__preview-selection {
    display: inline-block;
    border: 1px solid map-get(base-colors.$theme-colors, warning);
    border-radius: 8px;
    padding: 4px 8px;
    color: map-get(base-colors.$theme-colors, warning);
    font-size: 0.75rem;
  }

  &__candidate-branch {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border: 1px solid #e9ecef;
    border-radius: 0.25rem;
    cursor: pointer;
  }

  &__selected-branch {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border: 1px solid rgba(map-get(base-colors.$theme-colors, success), 0.5);
    border-radius: 0.25rem;
    background-color: rgba(map-get(base-colors.$theme-colors, success), 0.1);
  }

  &__select-button {
    height: 32px;
    width: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;

    &--selected {
      background-color: map-get(base-colors.$theme-colors, success);
    }
  }

  &__clean-button {
    height: 32px;
    width: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: map-get(base-colors.$theme-colors, danger);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
  }
}
</style>
